<template>
  <div class="grid grid-cols-3 p-6 gap-4">
    <pro-input
      label="email"
      :placeholder="'Email'"
      :rules="'required|email'"
      v-model="email"
      type="email"
    />
    <pro-input
      label="Mot de passe"
      :placeholder="'Mot de passe'"
      :rules="'required'"
      v-model="mdp"
      type="password"
    />
    <pro-input
      label="prix"
      :placeholder="'prix'"
      :rules="'required'"
      v-model="prix"
      type="number"
      unit="€"
    />
    <div class="flex">
      <pro-radio label="Madame" :value="gender" @change="change($event)">
        Madame
      </pro-radio>
      <pro-radio label="Monsieur" :value="gender" @change="change($event)">
        Monsieur
      </pro-radio>
    </div>
    <div class="flex items-center justify-center">
      <ProCheckbox
        class="w-2/5"
        :value="checkbox_value"
        id="checkBox"
        @check="checking($event)"
      >
        <div class="flex items-center justify-center">
          <div class="w-4 h-4 bg-promy-blue-250 rounded-md mr-2" />
          Ventes à Proximité
        </div>
      </ProCheckbox>
    </div>
    <ProTextarea
      label="Description"
      placeholder="Description"
      rules="required"
      v-model="description"
    />
    <div class="col-span-3 grid grid-cols-3">
      <div>
        <pro-button class="mx-2 mb-2" btn="secondary" icon>
          <span>button</span>
        </pro-button>
      </div>
      <div>
        <pro-button
          class="mx-2 mb-2"
          btn="primary"
          icon
          round
          v-tooltip.left="{
            content: 'Retirer des favoris',
            classes: classes,
          }"
        >
          <ProIcon name="war-vehicle" color="white" />
        </pro-button>
      </div>
      <div>
        <social-login-button btn="google" />
      </div>
      <div>
        <social-login-button btn="facebook" />
      </div>
      <div>
        <social-login-button btn="linkedin" />
      </div>
      <div>
        <pro-button class="mx-2 mb-2" btn="secondary" icon>
          <span @click="toggle">toggle toolBar</span>
        </pro-button>
      </div>
    </div>
    <div class="col-span-3 grid grid-cols-3">
      <transition name="slide">
        <ToolBar v-if="openToolBar" @close="closeToolBar">
          <div>content</div>
        </ToolBar>
      </transition>
    </div>

    <div class="col-span-3 bg-indigo-300">
      <div class="grid grid-cols-3 lg:grid-cols-2 sm:grid-cols-1">
        <Reseaux status="Mauvais" :reseauxDegree="1" />
        <Reseaux status="Mauvais" :reseauxDegree="2" />
        <Reseaux status="Bien" :reseauxDegree="3" />
        <Reseaux status="Bien" :reseauxDegree="4" />
      </div>
      <div class="grid grid-cols-3 lg:grid-cols-2 sm:grid-cols-1">
        <RisqueNumber
          RisqueStatus="Risque majeur"
          :RisqueValue="7"
          @HandleDetails="HandleDetails"
        />
      </div>
    </div>
    <div class="w-full bg-promy-gray-50 p-10 sm:p-2 md:col-span-3">
      <CircleChart
        chartId="Circle-chart"
        :labels="labelsCircle"
        title="Répartition de la population"
        :dataset="datasetCircle"
      />
    </div>
    <div class="w-full bg-promy-gray-50 p-10 sm:p-2 col-span-2 md:col-span-3">
      <BarreChart
        chartId="Barre-chart"
        title="Répartition de la population"
        :labels="labelsBarre"
        :dataset="datasetBarre"
      />
    </div>
    <div class="w-full bg-promy-gray-50 p-10 sm:p-2 md:col-span-3">
      <SemicircleChart
        chartId="Semi-circle-chart"
        title="Pollution sonore"
        :dataset="[70, 30]"
      />
    </div>
    <div
      class="w-full bg-promy-gray-50 px-36 py-10 col-span-2 md:p-10 sm:p-2 md:col-span-3"
    >
      <DetailsAccordion title="Parcelles">
        <template #right-side>
          <ProSelect
            class="md:hidden"
            :data="['parcelle 1', 'parcelle 2', 'parcelle 3']"
            @valueSelect="($event) => (parcelle_selected = $event)"
            v-model="parcelle_selected"
            :is_top="true"
          />
        </template>
        <template #body>
          <ProSelect
            class="hidden md:block mt-4"
            :data="['parcelle 1', 'parcelle 2', 'parcelle 3']"
            @valueSelect="($event) => (parcelle_selected = $event)"
            v-model="parcelle_selected"
          />
          <div class="grid grid-cols-3 gap-4 py-8 text-sm">
            <div class="sm:col-span-3">
              <p>Réf cadastrale</p>
              <p class="font-normal">B875 , B876 , B1041 , B1067 , B1068</p>
            </div>
            <div class="sm:col-span-3">
              <p>Total de parcelle(s)</p>
              <p class="font-normal">5</p>
            </div>
            <div class="sm:col-span-3">
              <p>Surf. total mesurée</p>
              <p class="font-normal">2576 m²</p>
            </div>
            <div class="sm:col-span-3">
              <p>N° de parcelle(s)</p>
              <p class="font-normal">875, 876 , 1041 , 1067 , 1068</p>
            </div>
            <div class="sm:col-span-3">
              <p>Total de bâtiment(s)</p>
              <p class="font-normal">6</p>
            </div>
            <div class="sm:col-span-3">
              <p>Surf. totale libre</p>
              <p class="font-normal">1800 m²</p>
            </div>
            <div class="sm:col-span-3">
              <p>Commune</p>
              <p class="font-normal">Marcenod</p>
            </div>
            <div class="sm:col-span-3">
              <p>Surf. totale cadastrale</p>
              <p class="font-normal">2564 m²</p>
            </div>
            <div class="sm:col-span-3">
              <p>Pinel</p>
              <p class="font-normal">C</p>
            </div>
          </div>
        </template>
      </DetailsAccordion>
    </div>
    <div class="w-full bg-promy-gray-50 p-10 sm:p-2 md:col-span-3">
      <PointsInteret />
    </div>
    <div
      class="w-full bg-promy-gray-50 border-2 border-promy-gray-100 px-24 py-8 md:p-0 md:col-span-3 md:h-36 relative"
    >
      <AddressAlert label="lyon" dept_region="rhone" :canSeeIcon="true" />
    </div>
    <!-- popup example -->
    <div>
      <pro-button class="mx-2 mb-2" btn="secondary" icon>
        <span @click="openModal">Open Modal</span>
      </pro-button>
      <Popup v-show="showModal" @close="showModal = false">
        <h2 class="text-2xl font-bold py-2 font-main" slot="title">
          Deconnexion
        </h2>
        <p class="text-base font-normal text-promy-gray-250" slot="body">
          Voulez vous se deconnécter
        </p>
        <div slot="buttons">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            save
          </button>
          <button
            @click="showModal = false"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </Popup>
    </div>
    <div>
      <pro-button class="mx-2 mb-2" btn="secondary" icon>
        <span @click="showToastMethod">Open Toast</span>
      </pro-button>
      <Alert
        v-show="showToast"
        @close="showToast = false"
        type="danger"
        title="Toast title"
        message="Curabitur viverra massa id urna malesuada congue quis eget neque."
      />
    </div>
    <div class="col-span-3">
      <accordion>
        <accordion-item
          class="shadow-box mb-4"
          v-for="(question, index) in questions"
          :key="index"
        >
          <template slot="accordion-trigger">
            <accordion-head
              :indice="index"
              :item_icon="{ name: 'marker', color: '#EB421D' }"
              :item_state="active_accordion"
              :title="question.title"
              :focus_color="{
                title: 'text-promy-green-350',
                action_icon: '#81C2DE',
              }"
              active_icon="downarrow"
              inactive_icon="uparrow"
            />
          </template>
          <template slot="accordion-content">
            <div class="bg-white pt-4 pb-6 px-6 border-solid border-t-2">
              <p class="text-promy-gray-800 font-arial text-sm">
                {{ question.body }}
              </p>
              <a
                v-if="question.text_url"
                class="flex justify-end items-center text-promy-green-300 font-main text-base font-extrabold"
                @click="action(question.url_value)"
                :class="{
                  hidden: question.url_value == 'candidater',
                }"
              >
                <div
                  class="border-transparent border-b-2 hover:border-promy-green-200 duration-200"
                >
                  <span>{{ question.text_url }}</span>
                  <i class="fa fa-arrow-right pl-2 text-sm"></i>
                </div>
              </a>
            </div>
            <div
              class="bg-white pt-4 pb-6 px-6 border-solid border-t-2 rounded-b-xl"
            >
              <p class="text-promy-gray-800 font-arial text-sm">
                {{ question.body }}
              </p>
              <a
                v-if="question.text_url"
                class="flex justify-end items-center text-promy-green-300 font-main text-base font-extrabold"
                @click="action(question.url_value)"
                :class="{
                  hidden: question.url_value == 'candidater',
                }"
              >
                <div
                  class="border-transparent border-b-2 hover:border-promy-green-200 duration-200"
                >
                  <span>{{ question.text_url }}</span>
                  <i class="fa fa-arrow-right pl-2 text-sm"></i>
                </div>
              </a>
            </div>
          </template>
        </accordion-item>
      </accordion>
    </div>
    <div>
      <pro-select
        :data="['Option1', 'Option2', 'Option3', 'Option4']"
        @valueSelect="($event) => (select_value = $event)"
        v-model="select_value"
      ></pro-select>
    </div>
    <div class="bg-promy-gray-50 p-10 sm:p-2 md:col-span-3">
      <MapType :layers="layers" />
    </div>
    <div class="border border-promy-gray-50 p-10 sm:p-2 md:col-span-3">
      <Itirinary start_point="Parcelle" end_point="Station de bus" />
      <div class="p-10 bg-promy-gray-150">
        <pro-auto-complete
          placeholder="Rechercher ..."
          class="w-full"
          :list="autocomplete_items"
          v-model="autocomplete_item"
          :args="['name']"
          @setItem="setItemForAutocomplete"
          :is_loading="is_autocomplete_fetching"
          :is_searchable="true"
        />
      </div>
    </div>
    <div class="flex space-x-6">
      <LoaderPromy classes="w-6" color="#10AAAF"></LoaderPromy>
    </div>
    <div class="col-span-3">
      <list-item
        class="py-14 px-28 sm:p-2"
        icon="marker"
        title="Rue Reyer 31200 Toulouse"
        subtitle="Haute-Garonne, Occitanie"
        status="green"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      parcelle_selected: null,
      email: null,
      mdp: null,
      gender: null,
      description: null,
      prix: null,
      showModal: false,
      showToast: false,
      classes: [
        'text-promy-green-350',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow',
        'text-xs',
        'bg-white',
        'mb-2',
      ],
      address_info: {
        label: 'Rue Reyer 31200 Toulouse',
        slug: 'Haute-Garonne, Occitanie',
      },
      openToolBar: false,
      labelsCircle: [
        '+ de 65 ans 30%',
        'de 25 à 64 ans 50%',
        '- de 25 ans 20%',
      ],
      datasetCircle: [30, 50, 20],
      labelsBarre: [
        'Sans activités',
        'Retraités',
        'Ouvriers',
        'Métier intermédiaires',
        'Métier superieur',
        'Agriculteurs exploitants',
        'Artisant/entrepreneur',
        'Cadres et prof',
      ],
      datasetBarre: [14748, 13707, 9456, 10597, 6431, 32463, 14748, 7123],
      questions: {
        q1: {
          title: 'Tutoriel Plateforme Lite',
          body: 'Découvrez notre plateforme et ses outils entièrement dédiés à votre activité et à votre recherche foncière.',
          text_url: 'Revoir le tutoriel',
          url_value: 'tutoriel',
        },
        q2: {
          title: 'Comment accéder à la plateforme complète ?',
          body: 'Votre version d’essai vous permet de découvrir les 1ères fonctionnalités de base de notre plateforme, pour accéder à la version complète et la Market place afin de vous positionner sur des projets disponibles, il vous suffit de faire une demande en cliquant sur « Candidater ». Notre service commercial prendra contact avec vous afin d’analyser vos besoins et vous proposer l’offre la plus adaptée.',
          text_url: 'Candidater',
          url_value: 'candidater',
        },
      },
      active_accordion: {
        _value: -1,
        is_active: (idx) => this.active_accordion._value === idx,
        set_active_accordion: (idx) => {
          this.active_accordion._value = idx
        },
        get_active_accordion: () => this.active_accordion._value,
      },
      select_value: '',
      layers: [
        {
          label: 'Cadastre',
          image: 'Cadastre.svg',
          is_active: false,
        },
      ],
      checkbox_value: '',
      autocomplete_items: [
        { name: 'aqua' },
        { name: 'fire' },
        { name: 'air' },
        { name: 'earth' },
      ],
      autocomplete_item: '',
      is_autocomplete_fetching: false,
    }
  },
  methods: {
    change(label) {
      this.gender = label
    },
    checking(value) {
      this.checkbox_value = value
    },
    setItemForAutocomplete(item_selected = null) {
      this.departement = item_selected ? item_selected.name : null
    },
    toggle() {
      this.openToolBar = !this.openToolBar
    },
    closeToolBar() {
      this.openToolBar = false
    },
    HandleDetails() {
      alert('Risque details')
    },
    openModal() {
      this.showModal = true
    },
    showToastMethod() {
      // this.showToast = true;
      // setTimeout(() => {
      //   this.showToast = false;
      // }, 2000);
      // this.$toast.warning(...this.toastConfig("Hello", "message", "warning"));
      this.toast('Hello', 'message')
    },
  },
}
</script>

<style></style>
